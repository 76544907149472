import axiosUtils from './axiosUtils.js'
import store from '../store/index'

const newApi = {
    data(data){
        const params = new URLSearchParams();
        for (const sProp in data) {
            params.append(sProp,data[sProp]);
        }
        return params;
    },

    /**
     * 微信网页授权获取code
     * */
    getCodeUrl(param) {
        return axiosUtils.post(store.state.devApiUrl + "/getCodeUrl", this.data(param));
    },

    /**
     * 微信公众号支付
     * */
    wxPubPay(param) {
        return axiosUtils.post(store.state.devApiUrl + "/wxPubPay", this.data(param));
    },

    /**
     * 支付宝App支付
     * */
    aliPay(param) {
        return axiosUtils.post(store.state.devApiUrl + "/aliPay", this.data(param));
    },

    /**
     * 查询微信或支付宝订单 是否完成
     * */
    queryOrderInfo(param) {
        return axiosUtils.post(store.state.devApiUrl + "/queryOrderInfo", this.data(param));
    },

}

export default newApi
