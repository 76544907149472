import Vue from 'vue'; // 引入VUE核心库
import Vuex from 'vuex'// 引入VUEX核心库  版本号在3.6.2以下的vuex适用于vue2

/**
 * vuex 是一个对数据进行集中式管理的工具
 * 1.数据传值繁琐问题
 * 2.实现了数据的共享
 * 我在组件 a 中用到这个方法，我在组件 b 中也用到这个方法，那么就可以把这个方法统一交给 vuex去完成。如果没有vuex，那我的组件a和b都要单独来设置一个方法 比如判断全局的登录状态
 * 由五部构成
 * 1. state：数据状态-------------------------------------  仓库当中的商品
 * 2. mutations：函数，同步操作。更改state----------------   工人。用来操作商品
 * 3. actions:函数，异步操作。通过调用mutations来更改数据---  老板，发送命令
 * 4. getters:计算属性
 * 5. modules：模块化。
* */

Vue.use(Vuex); // 安装VUEX

export default new Vuex.Store({  // 创建了一个VUEX仓库。

    // 数据状态
    state: {
        systemCount: 0,
        msgCount: 0,
        noticeCount: 0,
        is_login:0,
        // devApiUrl:"https://pay.baigongbao.com/Api",
        devApiUrl:"http://localhost:7979/Api",
    },

    mutations: {    },   // 更改数据状态。通过mutations来更改数据状态是唯一的正途。
                         // 是一个函数，函数的名字即是mutations的名字。建议大写。

    actions: {      },   // 行为。异步操作。通过该操作可以获得新的数据，得到数据以后可以通过调用mutations来更改数据状态。


    modules: {      },   //模块化

    getters:{       }    //计算属性


})

